import React from "react";

import {
  EntityAboutCard,
  EntitySwitch,
  EntityLinksCard,
  EntityHasSubcomponentsCard
} from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import { FeatureFlagged } from "@backstage/core-app-api";

import {
  isGithubInsightsAvailable,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsLanguagesCard,
} from "@roadiehq/backstage-plugin-github-insights";
import { Grid } from "@mui/material";

import { entityWarningContent } from "./EntityWarningContent";
import { novoAccessEntityContent } from "./NovoAccessEntityContent";
import { linguistEntityContent } from "./LinguistEntityContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";


export const overviewContent = (
  <Grid container spacing={3}>
    {entityWarningContent}
    <Grid container item xs={12}>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityAboutCard />
        </Grid>
        <EntitySwitch>
          <EntitySwitch.Case if={isGithubInsightsAvailable}>
            <Grid item xs={12}>
              <EntityGithubInsightsReadmeCard maxHeight={500} />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
        <Grid item xs={12}>
          <EntitySwitch>
            <EntitySwitch.Case if={isGithubInsightsAvailable}>
              <EntityGithubInsightsLanguagesCard />
            </EntitySwitch.Case>
            <EntitySwitch.Case>
              {linguistEntityContent}
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </Grid>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityCatalogGraphCard height={400} />
        </Grid>
        <Grid item xs={12}>
          <EntityHasSubcomponentsCard />
        </Grid>
        <Grid item xs={12}>
          <EntityLinksCard />
        </Grid>
        <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
          {novoAccessEntityContent}
        </FeatureFlagged>
      </Grid>
    </Grid>
  </Grid>
);
