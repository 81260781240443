import React from "react";
import { EntityNovoAccessCard } from "@internal/backstage-plugin-novoaccess/src/plugin";

import { Grid } from "@mui/material";

export const novoAccessEntityContent = (
  <Grid item xs={12}>
    <EntityNovoAccessCard />
  </Grid>
);
