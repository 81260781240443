import React from "react";

import {
  EntityLayout,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard
} from "@backstage/plugin-catalog";

import { Grid } from "@mui/material";
import { isGithubInsightsAvailable } from "@roadiehq/backstage-plugin-github-insights";

import { cicdContent } from "../content/CICDContent";
import { overviewContent } from "../content/OverviewContent";
import { techdocsContent } from "../content/TechDocsContent";
import { githubInsightsContent } from "../content/GithubInsightsContent";

export const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityDependsOnComponentsCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityDependsOnResourcesCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/github"
      title="GitHub Insights"
      if={isGithubInsightsAvailable}
    >
      {githubInsightsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
