import { FeatureFlag } from "@backstage/core-plugin-api";

export enum FeatureFlagValue {
  SHOW_NOVOACCESS_CARD = 'show-novoaccess-card',
}

export const featureFlags: (FeatureFlag & Omit<FeatureFlag, 'pluginId'>)[] = [
  {
    pluginId: '',
    name: FeatureFlagValue.SHOW_NOVOACCESS_CARD,
    description: 'Shows NovoAccess card on the Entity Page',
  },
]
