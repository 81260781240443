import React from "react";

import { EntityLayout } from "@backstage/plugin-catalog";
import {
  EntityGroupProfileCard,
  EntityOwnershipCard,
  EntityMembersListCard
} from "@backstage/plugin-org";

import {
  EntityGithubInsightsReadmeCard,
  isGithubInsightsAvailable
} from "@roadiehq/backstage-plugin-github-insights";
import { Grid } from "@mui/material";
import { FeatureFlagged } from "@backstage/core-app-api";

import { entityWarningContent } from "../content/EntityWarningContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";
import { novoAccessEntityContent } from "../content/NovoAccessEntityContent";
import { githubInsightsContent } from "../content/GithubInsightsContent";
import { EntityGithubGroupPullRequestsCard } from "@roadiehq/backstage-plugin-github-pull-requests";

export const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid container item xs={12} >
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityGroupProfileCard />
            </Grid>
            <Grid item xs={12}>
              <EntityMembersListCard showAggregateMembersToggle />
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityOwnershipCard />
            </Grid>
            <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
              {novoAccessEntityContent}
            </FeatureFlagged>
            <Grid item xs={12}>
              <EntityGithubInsightsReadmeCard maxHeight={350} />
              <EntityGithubGroupPullRequestsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/github"
      title="GitHub Insights"
      if={isGithubInsightsAvailable}
    >
      {githubInsightsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
