import React from "react";

import { EntitySwitch } from "@backstage/plugin-catalog";

import {
  EntityGithubInsightsEnvironmentsCard,
  EntityGithubInsightsComplianceCard,
} from "@roadiehq/backstage-plugin-github-insights";
import {
  EntityGithubPullRequestsOverviewCard,
  EntityGithubPullRequestsTable,
} from "@roadiehq/backstage-plugin-github-pull-requests";
import { Grid } from "@mui/material";

import { entityWarningContent } from "./EntityWarningContent";

export const githubInsightsContent = (
  <Grid container spacing={3}>
    {entityWarningContent}
    <Grid item xs={12}>
      <EntityGithubPullRequestsTable />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case>
        <Grid item lg={4} md={12}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
        <Grid item lg={4} md={12} >
          <EntityGithubInsightsComplianceCard />
        </Grid>
        <Grid item lg={4} md={12}>
          <EntityGithubInsightsEnvironmentsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
